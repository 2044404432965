import timg from '../images/resource/riitesh-mehtaa.webp'
import timg0 from '../images/resource/amit-sheth.webp'
import timg1 from '../images/resource/jimmy-mistry.webp'
// import timg2 from '../images/resource/jay-manglani.webp'
import timg3 from '../images/resource/micheal-conner.webp'
import timg12 from '../images/resource/shailza-taneja.jpg'

import tsimg from '../images/resource/riitesh-mehtaa.webp'
import tsimg0 from '../images/resource/amit-sheth.webp'
import tsimg1 from '../images/resource/jimmy-mistry.webp'
// import tsimg2 from '../images/resource/jay-manglani.webp'
import tsimg3 from '../images/resource/micheal-conner.webp'
// import tsimg8 from '../images/resource/team-16.jpg'
import tsimg12 from '../images/resource/shailza-taneja.jpg'

const Teams = [
   {
      Id: '1',
      tImg: timg,
      tsImg: tsimg,
      name: 'Riitesh Mehtaa',
      slug: 'Riitesh Mehtaa',
      title: "Serial Entrepreneur & Business Advisor, The Forttuna Group, UAE",
   },
   {
      Id: '2',
      tImg: timg0,
      tsImg: tsimg0,
      name: 'Amit Sheth',
      slug: 'Amit Sheth',
      title: "Award-winning Serial Entrepreneur & Philanthropist, India",
   },
   {
      Id: '3',
      tImg: timg1,
      tsImg: tsimg1,
      name: 'Jimmy Mistry',
      slug: 'Jimmy Mistry',
      title: "CMD, Della Group, Singapore",
   },
   {
      Id: '11',
      tImg: timg12,
      tsImg: tsimg12,
      name: 'Shailza Taneja',
      slug: 'Shailza Taneja',
      title: 'Strategic Advisor, Deloitte',
   },
]

export default Teams;