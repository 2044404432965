import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Banking-Tech-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)

    useEffect(() => {
        document.title = 'The Forttuna Global Excellence Awards: Business Excellence Awards';
    }, []);
    return (
        <Fragment>
             <Helmet>
                <meta name="description" content="Showcase your company's excellence on the world stage. Enter the Business Excellence Awards and connect with a global network of industry pioneers." />
                <meta property="og:title" content="The Forttuna Global Excellence Awards: Business Excellence Awards" />
                <meta property="og:description" content="Showcase your company's excellence on the world stage. Enter the Business Excellence Awards and connect with a global network of industry pioneers." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="keywords" content="Business Excellence Awards" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='The Forttuna Global Excellence Awards: Business Excellence Awards' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="Forttuna Business Excellence Awards" />
                        </div>
                        <h4 style={{color: '#fff'}}><strong>The Forttuna Global Excellence Awards: Business Excellence Awards</strong></h4>
                        <h1 style={{fontSize :'40px' , lineHeight: '43px', color: '#fff'}}><strong>First-ever Jury Led</strong> Business Excellence Awards: <strong>Be Recognized on the World Stage</strong></h1>
                        <p>The Forttuna Global Excellence Awards recognizes exceptional businesses that have redefined industry standards and driven positive change. By honoring organizations that demonstrate outstanding achievements in innovation, leadership, and social responsibility, the business excellence awards celebrate the spirit of excellence and inspire others to reach new heights.</p>
                        <h3 style={{color: '#fff'}}><strong>What are the Forttuna Global Excellence Awards?</strong></h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Recognize exceptional businesses:</strong> Honors organizations that have achieved outstanding performance in the global marketplace.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Celebrate innovation and leadership:</strong> Recognizes companies that drive industry advancements and demonstrate strong leadership.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Showcase global achievements:</strong> Provides a platform for businesses to highlight their contributions to international commerce.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Foster industry connections:</strong> Creates opportunities for networking and collaboration among global business leaders.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Enhance brand reputation:</strong> Elevates the brand and reputation of award winners on the international stage.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}><strong>Why Nominate?</strong></h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>International Acclaim:</strong> Gain prestigious recognition for your company's innovative ideas and leadership.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Inspire the Global Marketplace:</strong> Showcase your team's talent and inspire future business leaders.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Network with Industry Titans:</strong> Build valuable connections at our renowned awards ceremony.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Elevate Your Brand:</strong> Increase brand profile and attract new global opportunities.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Become a Global Powerhouse:</strong> Secure significant media exposure and position yourself as a leader.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Merit-Based Recognition:</strong> Benefit from a fair and transparent evaluation by industry experts.</li>
                        </ul>
                        <h4 style={{color: '#fff'}}><strong>Business Excellence Awards <a href="https://forttuna.co/award-categories/">Categories</a> :</strong></h4>
                        <p><strong>A. Architecture, Construction &amp; Engineering</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Angel Investor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>B. Automobile</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Angel Investor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>C. Aviation</strong></p>
                        <p><strong>D. Finance and Insurance</strong></p>
                        <p><strong>E. Fashion and Beauty</strong></p>
                        <p><strong>F. Entertainment and Media</strong></p>
                        <p><strong>G. Education</strong></p>
                        <p><strong>H. Hospitality</strong></p>
                        <p><strong>I. Manufacturing</strong></p>
                        <p><strong>J. Marketing</strong></p>
                        <p><strong>K. Market Research</strong></p>
                        <p><strong>L. Human Resources</strong></p>
                        <p><strong>M. Real Estate</strong></p>
                        <p><strong>N. SME&rsquo;s</strong></p>
                        <p><strong>O. Technology</strong></p>
                        <p><strong>P. Textile</strong></p>
                        <h5 style={{color: '#fff'}}><strong>Ready to shine a light?</strong></h5>
                        <h6 style={{color: '#fff'}}><strong>Nominate yourself or someone you know for recognition.</strong></h6>
                        <p>Showcase your company's excellence on the world stage. Enter the International Business Excellence Awards and connect with a global network of industry pioneers.</p>
                        <p><a href="https://participate.forttuna.co/">Participate Now</a>! Be a part of the <a href="https://india.forttuna.co/">Forttuna Entrepreneur Awards in India</a>.</p>
                        <p><a href="https://forttuna.co/awards/fintech-award/">Learn More</a> about the Excellence Award.</p>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
    )
};
export default EventSinglePage;
