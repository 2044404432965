import timg from '../images/resource/dr.-ebrahim-al-al-keem-al-zaabi.webp'
import timg0 from '../images/resource/adil-sajwani.webp'
import timg1 from '../images/resource/yaqoob-ali.webp'
import timg2 from '../images/resource/Karan-Kaushik.jpg'
import timg3 from '../images/resource/sultan-alameri.webp'
import timg4 from '../images/resource/moran-cerf.webp'
import timg5 from '../images/resource/riitesh-mehtaa.webp'
import timg6 from '../images/resource/Subramanyam-Yadavalli.webp'
import timg8 from '../images/resource/amit-sheth.webp'
import timg9 from '../images/resource/Ravi-Tharoor.jpg'
import timg10 from '../images/resource/Majid-Zangooei.jpg'
import timg11 from '../images/resource/Prof.-Sandra-Matz.jpg'

import tsimg from '../images/resource/dr.-ebrahim-al-al-keem-al-zaabi.webp'
import tsimg0 from '../images/resource/adil-sajwani.webp'
import tsimg1 from '../images/resource/yaqoob-ali.webp'
import tsimg2 from '../images/resource/Karan-Kaushik.jpg'
import tsimg3 from '../images/resource/sultan-alameri.webp'
import tsimg4 from '../images/resource/moran-cerf.webp'
import tsimg5 from '../images/resource/riitesh-mehtaa.webp'
import tsimg6 from '../images/resource/Subramanyam-Yadavalli.webp'
import tsimg8 from '../images/resource/amit-sheth.webp'
import tsimg9 from '../images/resource/Ravi-Tharoor.jpg'
import tsimg10 from '../images/resource/Majid-Zangooei.jpg'
import tsimg11 from '../images/resource/Prof.-Sandra-Matz.jpg'


const Teams = [
   {
      Id: '1',
      tImg: timg,
      tsImg: tsimg,
      name: 'Dr. Ebrahim Al Al Keem Al Zaabi',
      slug: 'Dr. Ebrahim Al Al Keem Al Zaabi',
      title: "Digital Transformation, Cybersecurity (IT & OT) & Artificial Intelligence (AI) Director, Government of Abu Dhabi, UAE",
   },
   {
      Id: '2',
      tImg: timg0,
      tsImg: tsimg0,
      name: 'Dr. Adil Sajwani',
      slug: 'Dr. Adil Sajwani',
      title: "Public Speaker, Healthcare Influencer, Family Medicine Physician, UAE",
   },
   {
      Id: '3',
      tImg: timg8,
      tsImg: tsimg8,
      name: 'Amit Sheth',
      slug: 'Amit Sheth',
      title: 'Award-winning Serial Entrepreneur & Philanthropist, India',
   },
   {
      Id: '4',
      tImg: timg1,
      tsImg: tsimg1,
      name: 'Yaqoob Al Ali',
      slug: 'Yaqoob Al Ali',
      title: "Executive Director & Private Advisor, Office of His Highness Sheikh Juma Bin Maktoum Al Maktoum, UAE",
   },
   {
      Id: '5',
      tImg: timg3,
      tsImg: tsimg3,
      name: 'Sultan Alameri',
      slug: 'Sultan Alameri',
      title: 'Deputy Vice Chancellor, Emirates College for Advanced Education, UAE',
   },
   {
      Id: '6',
      tImg: timg4,
      tsImg: tsimg4,
      name: 'Dr. Moran Cerf',
      slug: 'Dr. Moran Cerf',
      title: 'Professor of Neuroscience & Business, Columbia University, USA',
   },
   {
      Id: '7',
      tImg: timg5,
      tsImg: tsimg5,
      name: 'Riitesh Mehtaa',
      slug: 'Riitesh Mehtaa',
      title: 'Serial Entrepreneur & Business Advisor, The Forttuna Group, UAE',
   },
   {
      Id: '8',
      tImg: timg6,
      tsImg: tsimg6,
      name: 'Subramanyam Yadavalli',
      slug: 'subramanyam-yadavalli',
      title: 'CEO, Apollo Hospitals, AP & International, India',
   },
   {
      Id: '9',
      tImg: timg9,
      tsImg: tsimg9,
      name: 'Ravi Tharoor',
      slug: 'Ravi-Tharoor',
      title: 'Managing Director, Lyve Global, UAE and Former CEO, Khaleej Times',
   },
   {
      Id: '10',
      tImg: timg10,
      tsImg: tsimg10,
      name: 'Majid Zangooei',
      slug: 'Majid-Zangooei',
      title: 'Secretary General, Health Tourism Development Center of Islamic Countries',
   },
   {
      Id: '10',
      tImg: timg11,
      tsImg: tsimg11,
      name: 'Prof. Sandra Matz',
      slug: 'Sandra-Matz',
      title: 'Professor, Columbia Business School',
   },

]

export default Teams;