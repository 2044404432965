import timg from '../images/resource/manjulangani.webp'
import timg0 from '../images/resource/kaiser-raja.webp'
import timg1 from '../images/resource/moran-cerf.jpg'
import timg2 from '../images/resource/sylvia-paulig.webp'
import timg3 from '../images/resource/bimal-chajjar.webp'
import timg4 from '../images/resource/shallen-verma.webp'
import timg5 from '../images/resource/pratit-samdani.webp'

import tsimg from '../images/resource/manjulangani.webp'
import tsimg0 from '../images/resource/kaiser-raja.webp'
import tsimg1 from '../images/resource/moran-cerf.jpg'
import tsimg2 from '../images/resource/sylvia-paulig.webp'
import tsimg3 from '../images/resource/bimal-chajjar.webp'
import tsimg4 from '../images/resource/shallen-verma.webp'
import tsimg5 from '../images/resource/pratit-samdani.webp'
// import tsimg8 from '../images/resource/team-16.jpg'


const Teams = [
   {
      Id: '1',
      tImg: timg,
      tsImg: tsimg,
      name: 'Padmashri Dr. Manjula Anagani',
      slug: 'Padmashri Dr. Manjula Anagani',
      title: "Clinical Director, Women and Child Institute, Care Hospital, India",
   },
   {
      Id: '2',
      tImg: timg0,
      tsImg: tsimg0,
      name: 'Dr. Kaiser Raja',
      slug: 'Dr. Kaiser Raja',
      title: "Director of the Hepatology And Liver Transplantation Program at King’s College Hospital London, UAE",
   },
   {
      Id: '3',
      tImg: timg1,
      tsImg: tsimg1,
      name: 'Moran Cerf',
      slug: 'Moran Cerf',
      title: "Professor of Neuroscience & Business, Columbia University, USA",
   },
   // {
   //    Id: '4',
   //    tImg: timg2,
   //    tsImg: tsimg2,
   //    name: 'Dr. Sylvia Paulig',
   //    slug: 'Dr. Sylvia Paulig',
   //    title: "Ophthalmic Surgeon, Germany",
   // },
   {
      Id: '5',
      tImg: timg3,
      tsImg: tsimg3,
      name: 'Dr. Bimal Chhajer',
      slug: 'Dr. Bimal Chhajer',
      title: "Cardiologist, Founder, Saaol Heart Center, India",
   },
   {
      Id: '6',
      tImg: timg4,
      tsImg: tsimg4,
      name: 'Dr. Shallen Verma',
      slug: 'Dr. Shallen Verma',
      title: "Specialist Periodontics, UAE",
   },
   {
      Id: '6',
      tImg: timg5,
      tsImg: tsimg5,
      name: 'Dr. Pratit Samdani',
      slug: 'Dr. Pratit Samdani',
      title: "Oncologist, Physician & Intensivist, India",
   },

]

export default Teams;