import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Entrepreneur-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)
    useEffect(() => {
        document.title = 'The Forttuna Global Excellence Awards : Entrepreneur Awards';
    }, []);
    return (
        <Fragment>
            <Helmet>
                <meta name="description" content="A prestigious jury-led and merit-based entrepreneur awards program recognizing exceptional achievements across various industries." />
                <meta property="og:title" content="The Forttuna Global Excellence Awards : Entrepreneur Awards" />
                <meta property="og:description" content="A prestigious jury-led and merit-based entrepreneur awards program recognizing exceptional achievements across various industries." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="keywords" content="entrepreneur awards" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='The Forttuna Global Excellence Awards : Entrepreneur Awards' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="Forttuna entrepreneur awards" />
                        </div>
                        <h4 style={{color: '#fff'}}>The Forttuna Global Excellence Awards : Entrepreneur Awards</h4>
                        <h1 style={{fontSize :'40px' , lineHeight: '43px', color: '#fff'}}><strong>First-ever Jury Led Entrepreneur Awards: Be Recognized on the World Stage</strong></h1>
                        <p>The spirit of innovation and the courage to chase a vision &ndash; these are the hallmarks of a true entrepreneur. Yet, their tireless efforts often go unrecognized.</p>
                        <p>The Forttuna Global Excellence Awards celebrate these remarkable individuals through our prestigious Entrepreneur Awards. As we honor these remarkable achievements, we collectively illuminate the path for future generations of entrepreneurs, building a brighter future fueled by innovation, determination, and impactful businesses.</p>
                        <h3 style={{color: '#fff'}}>What are the Forttuna Global Excellence Awards?</h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>A prestigious jury-led and merit-based entrepreneur awards program recognizing exceptional achievements across various industries.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Award of Excellence to honor individuals and companies through entrepreneur awards making a significant impact on the global landscape.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>More than just global awards - a chance to connect and collaborate with like-minded individuals across diverse fields.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}>Why Participate?</h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Gain global recognition for your company's innovation and leadership.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Showcase individual talent and inspire others through achievement.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Network with industry leaders at our prestigious entrepreneur awards ceremony.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Elevate your brand profile and attract new opportunities.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Gain significant <a href="https://www.digicomlab.eu/research/media-exposure/">media exposure</a>, propelling your achievements into the spotlight.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Fair evaluation by a distinguished panel of international jury experts.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}>Entrepreneur Awards <a href="https://forttuna.co/award-categories/">Categories</a> :</h3>
                        <p><strong>A. Architecture, Construction &amp; Engineering</strong></p>
                        <p>Young Entrepreneur of the Year</p>
                        <p><strong>B. Automobile</strong></p>
                        <p>Young Entrepreneur of the Year</p>
                        <p><strong>C. Aviation</strong></p>
                        <p>Young Entrepreneur of the Year</p>
                        <p><strong>D. Finance and Insurance</strong></p>
                        <p>Young Entrepreneur of the Year</p>
                        <p><strong>E. Fashion and Beauty</strong></p>
                        <p>Young Entrepreneur of the Year</p>
                        <p><strong>F. Entertainment and Media</strong></p>
                        <p>Young Entrepreneur of the Year</p>
                        <p><strong>G. Education</strong></p>
                        <p>Young Entrepreneur of the Year</p>
                        <p><strong>H. Hospitality</strong></p>
                        <p>Young Entrepreneur of the Year</p>
                        <p><strong>I. Manufacturing</strong></p>
                        <p>Young Entrepreneur of the Year</p>
                        <p><strong>J. Marketing</strong></p>
                        <p>Young Entrepreneur of the Year</p>
                        <p><strong>K. Market Research</strong></p>
                        <p>Young Entrepreneur of the Year</p>
                        <p><strong>L. Human Resources</strong></p>
                        <p>Young Entrepreneur of the Year</p>
                        <p><strong>M. Real Estate</strong></p>
                        <p>Young Entrepreneur of the Year</p>
                        <p><strong>N. <a href="https://forttuna.co/awards/small-business-awards/">SME&rsquo;s</a></strong></p>
                        <p>Young Entrepreneur of the Year</p>
                        <p><strong>O. Technology</strong></p>
                        <p>Young Entrepreneur of the Year</p>
                        <p><strong>P. Textile</strong></p>
                        <p>Young Entrepreneur of the Year</p>
                        <h4 style={{color: '#fff'}}>Ready to shine a light?</h4>
                        <h5 style={{color: '#fff'}}>Participate yourself or someone you know for recognition.</h5>
                        <p>Let's celebrate your achievements together at this Entrepreneur Awards!</p>
                        <p><a href="https://participate.forttuna.co/">Participate Now or Start Your Participation</a></p>
                        <p><a href="https://india.forttuna.co/">Join us</a> in India and celebrate Entrepreneurship.</p>
                        <p><a href="https://forttuna.co/awards/education-awards/">Learn More</a> About the Excellence Award.</p>
                        <p>Know About <a href="https://forttuna.co/awards/advertising-awards/">Forttuna Advertising Awards!</a></p>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
    )
};
export default EventSinglePage;
