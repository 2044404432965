
import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import BlogSingle from '../../components/BlogD28/BlogD28';
import blogs from '../../api/blogs'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';


const BlogDetails = () => {

    const { slug } = useParams()

    const BlogDetails = blogs.find(item => item.slug === slug)

    useEffect(() => {
        document.title = 'Australian Small Business Champion Awards 2024 - Win Big';
    }, []);
    return (
        <Fragment>
             <Helmet>
                <meta name="description" content="Nominating your business for these Australian Small Business Champion Awards is not just about filling out a form; it's your chance to shine in your field." />
                <meta property="og:title" content="Australian Small Business Champion Awards 2024 - Win Big" />
                <meta property="og:description" content="Nominating your business for these Australian Small Business Champion Awards is not just about filling out a form; it's your chance to shine in your field." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="keywords" content="Australian Small Business Champion Awards" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'}/>
            <PageTitle pageTitle='Australian Small Business Champion Awards 2024 - Win Big' pagesub={''} pageTitle2={''}/>
            <BlogSingle />
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
    )
};
export default BlogDetails;
