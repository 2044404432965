import React from 'react'
import { Link } from 'react-router-dom'
import AllJuryIndia from '../../api/AlljuryIndia'
import shape1 from '../../images/background/pattern-4.png'
import shape2 from '../../images/background/pattern-6.png'
import shape3 from '../../images/background/pattern-5.png'

const Speakers = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="speakers-one speakers-onn" id="speakers">
            {/* <div className="speakers-one_pattern" style={{ backgroundImage: `url(${shape1})` }}></div> */}
            <div className="speakers-one_pattern-two" style={{ backgroundImage: `url(${shape2})` }}></div>
            <div className="auto-container" style={{marginTop: '120px'}}>
                <div className="sec-title title-anim centered">
                    {/* <div className="sec-title_title">Our Speakers</div> */}
                    <p className='color'>The Forttuna Global Excellence Awards Jury is comprised of a select group of individuals who are committed to the pursuit of excellence. They are deeply passionate about acknowledging remarkable accomplishments spanning <a href="https://forttuna.co/award-categories/">various industries</a>. These dedicated jury members bring with them a wealth of expertise and experience, each driven by a shared goal to identify and honor outstanding achievements that have made significant contributions to their respective fields. Through their rigorous evaluation process, the jury endeavors to ensure that only the most deserving individuals and organizations are recognized, thereby upholding the prestigious reputation of the Forttuna Global Excellence Awards. Their collective dedication to excellence award serves as a testament to their unwavering commitment to<a href="https://india.forttuna.co/"> celebrating and promoting</a> innovation, leadership, and excellence across the global landscape.</p>
                </div>
                <div className="row clearfix" style={{ justifyContent: 'center' }}>
                    {AllJuryIndia.slice(0, 15).map((team, aitem) => (
                        <div className="speaker-block_one col-lg-3 col-md-6 col-sm-12" key={aitem}>
                            <div className="speaker-block_one-inner">
                                <div className="speaker-block_one-image">
                                    <img src={team.tImg} alt="" />
                                    <div className="speaker-block_one-pattern"></div>
                                    <div className="speaker-block_one-social">
                                        <span className="speaker-block_one-share fa fa-share-alt"></span>
                                        <div className="speaker-block_one-social-list">
                                            <Link onClick={ClickHandler} to={`/speaker-details/${team.slug}`} className="facebook fa fa-linkedin"></Link>                                        </div>
                                    </div>
                                </div>
                                <div className="speaker-block_one-content">
                                    <h4 className="speaker-one_title" style={{color: '#fff'}}>{team.name}</h4>
                                    {/* <h4 className="speaker-one_title"><Link onClick={ClickHandler} to={`/speaker-details/${team.slug}`}>{team.name}</Link></h4> */}
                                    <div className="speaker-one_detail">{team.title}</div>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </section>
    )
}

export default Speakers;
