import React, { Fragment, useEffect } from 'react';

import { Helmet } from 'react-helmet';
import Navbar from '../../components/Navbar/Navbar';
import Hero from '../../components/hero/hero';
import About from '../../components/about/about';
import FunFact from '../../components/FunFact';
import Speakers3 from '../../components/Speakers3/Speakers3';
import WhyAttend from '../../components/WhyAttend/WhyAttend';
import EventsSection3 from '../../components/EventsSection3/EventsSection3';
import GallerySection from '../../components/GallerySection/GallerySection';
import Pricing from '../../components/Pricing/Pricing';
import VideoArea from '../../components/VideoArea/VideoArea';
import PartnerSection from '../../components/PartnerSection';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import BlogSection2 from '../../components/BlogSection2/BlogSection2';
import Footer2 from '../../components/footer2/Footer2';
import Scrollbar from '../../components/scrollbar/scrollbar';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'

const HomePage = () => {
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards';
    }, []); // The empty array ensures this runs only once when the component mounts
    
    useEffect(() => {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = 'favicon.ico';
    }, []);

    return (
        <Fragment>
            <Navbar />
            <Helmet>
                <meta name="description" content="The Forttuna Global Excellence Awards is dedicated to recognizing and honoring outstanding achievements across various industries." />
                <meta property="og:title" content="Forttuna Global Excellence Awards" />
                <meta property="og:description" content="The Forttuna Global Excellence Awards is dedicated to recognizing and honoring outstanding achievements across various industries." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Hero />
            <PartnerSection />
            <About />
            <FunFact />
            <Speakers3 />
            <WhyAttend />
            <EventsSection3 />
            {/* <Pricing /> */}
            <VideoArea />
            <BlogSection2 />
            <GallerySection />
            <Subscribe2 />
            <Footer2 />
            <Scrollbar />
        </Fragment>
    );
};

export default HomePage;
