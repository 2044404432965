import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/award/Forttuna-Global-Excellence-AwardsWomen-In-Business-Awards.webp'
import sSimg from '../../images/award/Business-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards: Women In Business Awards';
    }, []);
    return (
        <Fragment>
             <Helmet>
                <meta name="description" content="A prestigious jury-led and merit-based hospitality awards program recognizing exceptional achievements across various industries." />
                <meta property="og:title" content="Forttuna Global Excellence Awards: Women In Business Awards" />
                <meta property="og:description" content="A prestigious jury-led and merit-based hospitality awards program recognizing exceptional achievements across various industries." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="keywords" content="women in business awards" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='Forttuna Global Excellence Awards: Women In Business Awards' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="Forttuna women in business awards" />
                        </div>
                        <h3 style={{color: '#fff'}}>Forttuna Global Excellence Awards: Women In Business Awards</h3>
                        <h1 style={{fontSize :'40px' , lineHeight: '43px', color: '#fff'}}><strong>First-ever Jury Led Women In Business Awards: Be Recognized on the World Stage</strong></h1>
                        <p>The business world thrives on innovation, resilience, and leadership &ndash; qualities that women in business consistently demonstrate.</p>
                        <p>The Forttuna Global Excellence Awards - Women In Business Awards provide a platform to acknowledge their remarkable contributions and the impact they create. Recognition on a global stage will pave the way for future generations of <a href="https://www.apa.org/topics/women-girls/female-leaders-make-work-better">female leaders</a> and entrepreneurs.</p>
                        <h3 style={{color: '#fff'}}>What are the Forttuna Global Excellence Awards?</h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>A prestigious jury-led and merit-based women in business awards program recognizing exceptional achievements across various industries.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Award of Excellence to honor individuals and companies through women in business awards making a significant impact on the global landscape.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>More than just global awards - a chance to connect and collaborate with like-minded individuals across diverse fields.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}>Why Participate?</h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Gain global recognition for your company's innovation and leadership.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Showcase individual talent and inspire others through achievement.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Network with industry leaders at our prestigious women in business awards ceremony.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Elevate your brand profile and attract new opportunities.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Gain significant media exposure, propelling your achievements into the spotlight.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Fair evaluation by a distinguished panel of international jury experts.</li>
                        </ul>
                        <h4 style={{color: '#fff'}}>Women In Business Awards <a href="https://forttuna.co/award-categories/">Categories</a> :</h4>
                        <p><strong>A. Architecture, Construction &amp; Engineering</strong></p>
                        <p>Business Woman of the Year</p>
                        <p><strong>B. Automobile</strong></p>
                        <p>Business Woman of the Year</p>
                        <p><strong>C. Aviation</strong></p>
                        <p>Business Woman of the Year</p>
                        <p><strong>D. Finance and Insurance</strong></p>
                        <p>Business Woman of the Year</p>
                        <p><strong>E. Fashion and Beauty</strong></p>
                        <p>Business Woman of the Year</p>
                        <p><strong>F. Entertainment and Media</strong></p>
                        <p>Business Woman of the Year</p>
                        <p><strong>G. Education</strong></p>
                        <p>Business Woman of the Year</p>
                        <p><strong>H. Hospitality</strong></p>
                        <p>Business Woman of the Year</p>
                        <p><strong>I. Manufacturing</strong></p>
                        <p>Business Woman of the Year</p>
                        <p><strong>J. Marketing</strong></p>
                        <p>Business Woman of the Year</p>
                        <p><strong>K. Market Research</strong></p>
                        <p>Business Woman of the Year</p>
                        <p><strong>L. Human Resources</strong></p>
                        <p>Business Woman of the Year</p>
                        <p><strong>M. Real Estate</strong></p>
                        <p>Business Woman of the Year</p>
                        <p><strong>N. SME&rsquo;s</strong></p>
                        <p>Business Woman of the Year</p>
                        <p><strong>O. Technology</strong></p>
                        <p>Business Woman of the Year</p>
                        <p><strong>P. Textile</strong></p>
                        <p>Business Woman of the Year</p>
                        <h5 style={{color: '#fff'}}>Ready to shine a light?</h5>
                        <h6 style={{color: '#fff'}}>Participate yourself or someone you know for recognition.</h6>
                        <p>Let's celebrate your achievements together at this Women In Business Awards!</p>
                        <p><a href="https://participate.forttuna.co/">Participate Now or Start Your Participation</a></p>
                        <p><a href="https://india.forttuna.co/">Participate</a> in Forttuna India Awards.</p>
                        <p><a href="https://forttuna.co/awards/manufacturing-awards/">Learn More</a> About the Excellence Award.</p>
                        <p>Know About <a href="https://forttuna.co/awards/education-awards/">Forttuna Education Awards</a>.</p>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
    )
};
export default EventSinglePage;
