import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/health-support.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards : Health Support Service Award';
    }, []);
    return (
        <Fragment>
             <Helmet>
                <meta name="description" content="Award of Excellence to honor individuals and companies through health support services award making an impact on the global landscape." />
                <meta property="og:title" content="Forttuna Global Excellence Awards : Health Support Service Award" />
                <meta property="og:description" content="Award of Excellence to honor individuals and companies through health support services award making an impact on the global landscape." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="keywords" content="Health Support Services Award" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='Forttuna Global Excellence Awards : Health Support Service Award' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="Forttuna Health Support Services Award" />
                        </div>
                        <h4 style={{color: '#fff'}}>The Forttuna Global Excellence Awards: Health Support Services Award</h4>
                        <h1 style={{fontSize :'40px' , lineHeight: '43px', color: '#fff'}}><strong>First-ever Jury Led Health Support Services Award: Be Recognized on the World Stage</strong></h1>
                        <p>The Healthcare industry thrives on a delicate balance. While doctors diagnose and treat, a dedicated team of health support service professionals works tirelessly behind the scenes.</p>
                        <p>The Forttuna Global Excellence Awards - Health Support Services Awards provide a platform to acknowledge their remarkable contributions. Let's celebrate the unseen heroes who are silent partners in patient care and inspire the future of <a href="https://www.thenationalcouncil.org/resources/the-comprehensive-healthcare-integration-framework/">comprehensive healthcare</a>.</p>
                        <h3 style={{color: '#fff'}}>What are the Forttuna Global Excellence Awards?</h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>A prestigious jury-led and merit-based health support services award program recognizing exceptional achievements across various industries.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Award of Excellence to honor individuals and companies through health support services award making an impact on the global landscape.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>More than just global awards - a chance to connect and collaborate with like-minded individuals across diverse fields.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}>Why Participate?</h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Gain global recognition for your company's innovation and leadership.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Showcase individual talent and inspire others through achievement.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Network with industry leaders at our prestigious health support services award ceremony.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Elevate your brand profile and attract new opportunities.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Gain significant media exposure, propelling your achievements into the spotlight.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Fair evaluation by a distinguished panel of international jury experts.</li>
                        </ul>
                        <h4>Health Support Services Award <a href="https://forttuna.co/award-categories/">Categories</a> :</h4>
                        <p><strong>A. Ayush, Spa, Wellness and Yoga Category</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Ayurveda Centre Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Medical Spa/Medical Resort Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Yoga Centre Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Wellness Centre/ Retreat Of the Year</li>
                        </ol>
                        <p><strong>B. Company and Brands</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Health Insurance Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Long-Tem Care Provider Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pharma Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pharmacy Retail Chain Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Healthcare Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Medical Equipment Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Start-up Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Public Healthcare Initiative Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Medical Product Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Marketing Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Insurance and Finance Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Technology Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Education Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Veterinary Company Of the Year</li>
                        </ol>
                        <p><strong>C. CXO&rsquo;s Health &amp; Wellness</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO Of the Year</li>
                        </ol>
                        <p><strong>D. Hospital &amp; Clinic Category</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Hospital Chain Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Hospital Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Clinic Chain Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Dental Clinic Chain Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Fertility Centre Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Cancer Hospital Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Orthopedic Hospital Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Eye Hospital Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Hair Clinic Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Green Hospital Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Aesthetic/Cosmetic Surgery Centre Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Cryo Clinic Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Multi-Speciality Hospital Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Single Speciality Hospital Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Veterinary Clinic Of the Year</li>
                        </ol>
                        <p><strong>E. Individual Category</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Physician Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Cardiologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Neurologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Gastroenterologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Nephrologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Dietician Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Physiotherapist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Gynaecologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>ENT Specialist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatrician Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Cosmetic Plastic Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Aesthetic Consultant Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Cosmetologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>AYUSH Physician Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Dentist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Entrepreneur Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Endocrinologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Gastrointestinal Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Oncologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Oncologist Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Radiologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emergency Physician Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Orthopaedic Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Dermatologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Urologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Micro Vascular Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Cardiothoracic Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Neurosurgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Intensivist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Neonatal intensivist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>General Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Orthopaedic Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Psychiatrist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Ophthalmologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Infectious Disease Specialist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Anesthesiologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Palliative Care Specialist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Allergic/Immunologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pathologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Rheumatologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Vascular Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pulmonologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Haemotologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Nephrology Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Fertility Specialist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Infectious Disease Physician Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Minimally Invasive Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Sleep Medicine Specialist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Reproductive Endocrinologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Clinical Geneticist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pain Management Specialist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Cardiologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Neurologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Orthopedic Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Intensivist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Hematologist/Oncologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Rheumatologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Gastroenterologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Endocrinologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Pulmonologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Nephrologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Infectious Disease Specialist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Allergic/Immunologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Dermatologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Hematologist/Oncologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Urologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Neurosurgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Ophthalmologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Emergency Physician Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Anesthesiologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Intensive Care Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Bariatric Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Colorectal Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Hand and Upper Extremity Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Vascular and Endovascular Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Surgical Oncologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Thoracic Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Neuro-Oncologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Gynecologic Oncologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Gynecologic Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Oncology Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Reproductive Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Craniofacial Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Reconstructive Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Transplant Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Geriatrician Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Leader Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Wellness Leader Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Veterinarian Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Healthcare Leader Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Wellness Leader Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Education Leader Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Technology Leader Of the Year</li>
                        </ol>
                        <p><strong>F. Medical College/Institute Category</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Medical College Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Medical Institute Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>AYUSH College Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>AYUSH Institute Of the Year</li>
                        </ol>
                        <h5 style={{color: '#fff'}}>Ready to shine a light?</h5>
                        <h6 style={{color: '#fff'}}> Nominate yourself or someone you know for recognition.</h6>
                        <p>Let's celebrate your achievements together at this Health Support Services Award!</p>
                        <p><a href="https://participate.forttuna.co/">Participate Now or Start Your Participation</a> <a href="https://india.forttuna.co/">Join us</a> in India!</p>
                        <p><a href="https://forttuna.co/awards/manufacturing-and-occupation/">Learn More</a> About the Excellence Award.</p>
                        <p>Know About <a href="https://forttuna.co/awards/wellness-awards-2024/">Wellness Awards 2024.</a></p>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
    )
};
export default EventSinglePage;
