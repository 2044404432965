import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Manufacturing-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards: Manufacturing and Occupation';
    }, []);
    return (
        <Fragment>
             <Helmet>
                <meta name="description" content="Network with industry leaders at our prestigious manufacturing and associated industries and occupations award ceremony." />
                <meta property="og:title" content="Forttuna Global Excellence Awards: Manufacturing and Occupation" />
                <meta property="og:description" content="Network with industry leaders at our prestigious manufacturing and associated industries and occupations award ceremony." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="keywords" content="Forttuna Global Excellence Awards: Manufacturing and Occupation" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='Forttuna Global Excellence Awards: Manufacturing and Occupation' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="Forttuna Manufacturing and Associated Industries and Occupations Award" />
                        </div>
                        <h4 style={{color: '#fff'}}>The Forttuna Global Excellence Awards: Manufacturing and Associated Industries and Occupations Award</h4>
                        <h1 style={{fontSize :'40px' , lineHeight: '43px', color: '#fff'}}><strong>First-ever Jury Led Manufacturing and Associated Industries and Occupations Award: Be Recognized on the World Stage</strong></h1>
                        <p>The engine of our global economy hums thanks not only to manufacturers themselves, but also to the vast network of professionals in associated industries and occupations.</p>
                        <p>The Forttuna Global Excellence Awards - Manufacturing and Associated Industries and Occupations Awards provide a platform to acknowledge their remarkable contributions and the impact they create. These are the unsung heroes who make the world of manufacturing a well-oiled machine.</p>
                        <h3 style={{color: '#fff'}}>What are the Forttuna Global Excellence Awards?</h3>
                        <ul>
                        <li>A prestigious jury-led and merit-based award program recognizing exceptional achievements across various industries.</li>
                        <li>Award of Excellence to honor individuals and companies through manufacturing and associated industries and occupations award making a significant impact on the global landscape.</li>
                        <li>More than just manufacturing associated industries award - a chance to connect and collaborate with like-minded individuals across diverse fields</li>
                        </ul>
                        <h3 style={{color: '#fff'}}>Why Participate?</h3>
                        <ul>
                        <li>Gain global recognition for your company's innovation and leadership.</li>
                        <li>Showcase individual talent and inspire others through achievement.</li>
                        <li>Network with industry leaders at our prestigious manufacturing and associated industries and occupations award ceremony.</li>
                        <li><a href="https://insightssuccess.com/4-ways-to-elevate-your-brand-this-year/">Elevate</a> your brand profile and attract new opportunities.</li>
                        <li>Gain significant media exposure, propelling your achievements into the spotlight.</li>
                        <li>Fair evaluation by a distinguished panel of international jury experts.</li>
                        </ul>
                        <h4 style={{color: '#fff'}}>Manufacturing and Associated Industries and Occupations Award Categories:</h4>
                        <p><strong>Manufacturing Awards</strong></p>
                        <ol>
                        <li>Angel Investor of the Year</li>
                        <li>Business Leader of the Year</li>
                        <li>Business Woman of the Year</li>
                        <li>CEO of the Year</li>
                        <li>CFO of the Year</li>
                        <li>CHRO of the Year</li>
                        <li>CIO of the Year</li>
                        <li>CMO of the Year</li>
                        <li>COO of the Year</li>
                        <li>CTO of the Year</li>
                        <li>Emerging Business Leader of the Year</li>
                        <li>Entrepreneur of the Year</li>
                        <li>Influencer of the Year</li>
                        <li>Lifetime Achievement Award</li>
                        <li>Mentor of the Year</li>
                        <li>Recruiter of the Year</li>
                        <li>Start-up of the Year</li>
                        <li>Venture Capitalist of the Year</li>
                        <li>Young Business Leader of the Year</li>
                        <li>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>Textile Awards</strong></p>
                        <ol>
                        <li>Angel Investor of the Year</li>
                        <li>Business Leader of the Year</li>
                        <li>Business Woman of the Year</li>
                        <li>CEO of the Year</li>
                        <li>CFO of the Year</li>
                        <li>CHRO of the Year</li>
                        <li>CIO of the Year</li>
                        <li>CMO of the Year</li>
                        <li>COO of the Year</li>
                        <li>CTO of the Year</li>
                        <li>Emerging Business Leader of the Year</li>
                        <li>Entrepreneur of the Year</li>
                        <li>Influencer of the Year</li>
                        <li>Lifetime Achievement Award</li>
                        <li>Mentor of the Year</li>
                        <li>Recruiter of the Year</li>
                        <li>Start-up of the Year</li>
                        <li>Venture Capitalist of the Year</li>
                        <li>Young Business Leader of the Year</li>
                        <li>Young Entrepreneur of the Year</li>
                        </ol>
                        <h2 style={{color: '#fff'}}>Ready to shine a light?</h2>
                        <h2 style={{color: '#fff'}}>Nominate yourself or someone you know for recognition.</h2>
                        <p>Let's celebrate your achievements together at this Manufacturing and Associated Industries and Occupations Award!</p>
                        <p><a href="https://participate.forttuna.co/">Participate Now or Start Your Participation</a></p>
                        <p><a href="https://participate.forttuna.co/">Nominate</a> to be a part of Forttuna India.</p>
                        <p>Explore more <a href="https://forttuna.co/award-categories/">Award Categories</a>.</p>
                        <p><a href="https://forttuna.co/awards/sme-business-awards/">Learn More</a> About the <a href="https://forttuna.co/awards/health-support-services-award/">Excellence Award</a>.</p>
                        <p>Know About <a href="https://forttuna.co/awards/manufacturing-awards/">Manufacturing Awards</a>.</p>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
    )
};
export default EventSinglePage;
