import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Wellness-Awards-2024.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards: Wellness Awards 2024';
    }, []);
    return (
        <Fragment>
             <Helmet>
                <meta name="description" content="The Forttuna Wellness Awards 2024 recognize excellence in all areas of well-being, from fitness and mindfulness to nutrition." />
                <meta property="og:title" content="Forttuna Global Excellence Awards: Wellness Awards 2024" />
                <meta property="og:description" content="The Forttuna Wellness Awards 2024 recognize excellence in all areas of well-being, from fitness and mindfulness to nutrition." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="keywords" content="Wellness Awards 2024" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='The Forttuna Global Excellence Awards: Health Insurance Awards' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="Forttuna Wellness Awards 2024" />
                        </div>
                        <h4 style={{color: '#fff'}}>Forttuna Global Excellence Awards: Wellness Awards 2024</h4>
                        <h1 style={{fontSize :'40px' , lineHeight: '43px', color: '#fff'}}><strong>First-ever Jury Led Wellness Awards 2024: Be Recognized on the World Stage</strong></h1>
                        <p>Well-being extends far beyond the realm of traditional medicine in today's world. From fitness gurus to mindfulness experts, nutrition specialists to sleep science advocates, these individuals are redefining what it means to thrive.</p>
                        <p>The Forttuna Global Excellence Awards proudly presents the Wellness Awards 2024, recognizing the innovators and changemakers who are shaping a <a href="https://www.joyce.edu/blog/approaching-problems-holistically/">holistic approach</a> to health.</p>
                        <h3 style={{color: '#fff'}}>What are the Forttuna Global Excellence Awards?</h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>A prestigious jury will evaluate your work, celebrating your exceptional achievements in wellness awards 2024</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Showcase your company's innovative approach to wellness, attracting new partnerships with increased industry visibility.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Connect with fellow health and wellness leaders, share best practices, and forge valuable connections that can shape the future of well-being.</li>
                        </ul>
                        <h4 style={{color: '#fff'}}>Why Participate?</h4>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>A prestigious jury will evaluate your work and elevate your company's impact on a global scale.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Gain increased brand visibility, attracting new partnerships and propelling your message of well-being forward.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Network with fellow wellness pioneers, share best practices, and forge valuable connections that can shape the future of health.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Inspire the next generation at our exclusive ceremony, showcasing your success and motivating others to join the movement.</li>
                        </ul>
                        <h5 style={{color: '#fff'}}>Health and Wellness Awards 2024 Categories:</h5>
                        <p><strong>A. Ayush, Spa, Wellness and Yoga Category</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Ayurveda Centre Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Medical Spa/Medical Resort Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Yoga Centre Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Wellness Centre/ Retreat Of the Year</li>
                        </ol>
                        <p><strong>B. Company and Brands</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Health Insurance Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Long-Tem Care Provider Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pharma Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pharmacy Retail Chain Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Healthcare Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Medical Equipment Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Start-up Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Public Healthcare Initiative Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Medical Product Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Marketing Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Insurance and Finance Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Technology Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Education Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Veterinary Company Of the Year</li>
                        </ol>
                        <p><strong>C. CXO&rsquo;s Health &amp; Wellness</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO Of the Year</li>
                        </ol>
                        <p><strong>D. Hospital &amp; Clinic Category</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Hospital Chain Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Hospital Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Clinic Chain Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Dental Clinic Chain Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Fertility Centre Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Cancer Hospital Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Orthopedic Hospital Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Eye Hospital Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Hair Clinic Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Green Hospital Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Aesthetic/Cosmetic Surgery Centre Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Cryo Clinic Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Multi-Speciality Hospital Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Single Speciality Hospital Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Veterinary Clinic Of the Year</li>
                        </ol>
                        <p><strong>E. Individual Category</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Physician Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Cardiologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Neurologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Gastroenterologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Nephrologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Dietician Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Physiotherapist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Gynaecologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>ENT Specialist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatrician Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Cosmetic Plastic Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Aesthetic Consultant Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Cosmetologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>AYUSH Physician Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Dentist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Entrepreneur Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Endocrinologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Gastrointestinal Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Oncologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Oncologist Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Radiologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emergency Physician Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Orthopaedic Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Dermatologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Urologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Micro Vascular Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Cardiothoracic Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Neurosurgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Intensivist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Neonatal intensivist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>General Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Orthopaedic Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Psychiatrist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Ophthalmologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Infectious Disease Specialist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Anesthesiologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Palliative Care Specialist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Allergic/Immunologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pathologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Rheumatologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Vascular Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pulmonologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Haemotologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Nephrology Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Fertility Specialist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Infectious Disease Physician Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Minimally Invasive Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Sleep Medicine Specialist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Reproductive Endocrinologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Clinical Geneticist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pain Management Specialist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Cardiologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Neurologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Orthopedic Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Intensivist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Hematologist/Oncologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Rheumatologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Gastroenterologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Endocrinologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Pulmonologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Nephrologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Infectious Disease Specialist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Allergic/Immunologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Dermatologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Hematologist/Oncologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Urologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Neurosurgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Ophthalmologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Emergency Physician Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Anesthesiologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Intensive Care Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Bariatric Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Colorectal Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Hand and Upper Extremity Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Vascular and Endovascular Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Surgical Oncologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Thoracic Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Neuro-Oncologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Gynecologic Oncologist Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Gynecologic Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pediatric Oncology Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Reproductive Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Craniofacial Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Reconstructive Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Transplant Surgeon Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Geriatrician Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Leader Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Wellness Leader Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Veterinarian Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Healthcare Leader Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Wellness Leader Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Education Leader Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Technology Leader Of the Year</li>
                        </ol>
                        <p><strong>F. Medical College/Institute Category</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Medical College Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Medical Institute Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>AYUSH College Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>AYUSH Institute Of the Year</li>
                        </ol>
                        <p>Learn about the other categories</p>
                        <h6 style={{color: '#fff'}}>Ready to shine a light?</h6>
                        <p>The Forttuna Wellness Awards 2024 recognize excellence in all areas of well-being, from fitness and mindfulness to nutrition.</p>
                        <p><a href="https://participate.forttuna.co/">Participate Now</a>!</p>
                        <p><a href="https://india.forttuna.co/">Participate now</a> in Forttuna Awards in India!</p>
                        <p><a href="https://forttuna.co/awards/health-insurance-awards/">Know About</a> the Excellence Award.</p>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
    )
};
export default EventSinglePage;
