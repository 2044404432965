import timg from '../images/resource/manjulangani.webp'
import timg0 from '../images/resource/Subramanyam-Yadavalli2.webp'
import timg1 from '../images/resource/moran-cerf.jpg'
import timg2 from '../images/resource/Dr.-Sylvia-Paulig.webp'
import timg3 from '../images/resource/bimal-chajjar.webp'
import timg4 from '../images/resource/Dr.-Shallen-Verma.webp'
import timg5 from '../images/resource/pratit-samdani.webp'

import tsimg from '../images/resource/manjulangani.webp'
import tsimg0 from '../images/resource/Subramanyam-Yadavalli2.webp'
import tsimg1 from '../images/resource/moran-cerf.jpg'
import tsimg2 from '../images/resource/Dr.-Sylvia-Paulig.webp'
import tsimg3 from '../images/resource/bimal-chajjar.webp'
import tsimg4 from '../images/resource/Dr.-Shallen-Verma.webp'
import tsimg5 from '../images/resource/pratit-samdani.webp'
// import tsimg8 from '../images/resource/team-16.jpg'


const Teams = [
   {
      Id: '1',
      tImg: timg,
      tsImg: tsimg,
      name: 'Padmashri Dr. Manjula Anagani',
      slug: 'Padmashri Dr. Manjula Anagani',
      title: "Clinical Director, Women and Child Institute, Care Hospital, India",
   },
   {
      Id: '2',
      tImg: timg0,
      tsImg: tsimg0,
      name: 'Subramanyam Yadavalli',
      slug: 'Subramanyam Yadavalli',
      title: 'CEO, Apollo Hospitals',
   },
   {
      Id: '5',
      tImg: timg3,
      tsImg: tsimg3,
      name: 'Dr. Bimal Chhajer',
      slug: 'Dr. Bimal Chhajer',
      title: "Cardiologist, Founder, Saaol Heart Center, India",
   },
   {
      Id: '6',
      tImg: timg5,
      tsImg: tsimg5,
      name: 'Dr. Pratit Samdani',
      slug: 'Dr. Pratit Samdani',
      title: "Oncologist, Physician & Intensivist, India",
   },

]

export default Teams;