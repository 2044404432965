import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import { Link } from 'react-router-dom'
import pIcon1 from '../../images/background/Participation-Guideline.jpg';
import Navbar from '../../components/Navbar/Navbar';
import PastWinner from '../../components/PastWinner/PastWinner';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer2 from '../../components/footer2/Footer2';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';

const SpeakerPage = (props) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    useEffect(() => {
        document.title = 'Forttuna Awards | India Edition | Participation Guidelines';
    }, []);
    return (
        <Fragment>
            <Helmet>
                <meta name="description" content="At the Excellence Awards, we work closely with our dedicated research team to identify a vast pool of deserving candidates." />
                <meta property="og:title" content="Forttuna Awards | India Edition | Participation Guidelines" />
                <meta property="og:description" content="At the Excellence Awards, we work closely with our dedicated research team to identify a vast pool of deserving candidates." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1} />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar />
            <section className="page-title" style={{ backgroundImage: `url(${pIcon1})` }}>
                <div className="auto-container" >
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="left-box">
                            <div className="page-title_big">PARTICIPATION GUIDELINES</div>
                            <h2 className="page-title_heading">PARTICIPATION GUIDELINES</h2>
                        </div>
                        {/* <ul className="bread-crumb clearfix">
                            <li><Link to="/home">Home</Link></li>
                            <li>PARTICIPATION GUIDELINES</li>
                        </ul> */}
                    </div>
                </div>
            </section>
            <section style={{marginTop: '120px'}}> 
                <div className="sec-title title-anim centered">
                    <h2 class="sec-title_heading">Invitation Criteria For The Excellence Awards</h2>
                </div>
                <div className='mrl-12'>
                    <p className='pera'>At the Excellence Awards, we work closely with our dedicated research team to identify a vast pool of deserving candidates. We meticulously curate a list of distinguished group of <a href="https://forttuna.co/award-categories/">individuals and organizations</a> from a vast pool of candidates for this award of excellence. We consider various factors in the excellence awards including recommendations, educational background, professional experience, leadership qualities, achievements, and, where applicable, the financial stability of companies or organizations.
                    <br/>
                    <br/>
                    This curated list for excellence awards is then invited to <a href="https://india.forttuna.co/">participate</a> in a comprehensive participation interview to share more about their journey. After the interview, participants are requested to complete a questionnaire prepared by the jury for evaluation in the award of excellence.
                    <br/>
                    <br/>
                    Subsequently, our esteemed and distinguished panel of jury meticulously evaluates the information and selects the final awardees through a rigorous process in the global awards. This meticulous approach ensures that only the most deserving individuals and entities are included in the final list of awardees of the global awards.</p>
                </div>
            </section>
            <div class="timeline">
                <div className="sec-title title-anim centered">
                    <h2 class="sec-title_heading">Participation Process</h2>
                </div>
                <div class="container">
                    <div class="row">
                    <div class="col-lg-12">
                        <div class="timeline-container">
                        <div class="timeline-end">
                            <p>Now</p>
                        </div>
                        <div class="timeline-continue">

                            <div class="row timeline-right">
                            <div class="col-md-6">
                                <p class="timeline-date"></p>
                            </div>
                            <div class="col-md-6">
                                <div class="timeline-box">
                                <div class="timeline-icon">
                                    <i class="fa fa-gift"></i>
                                </div>
                                <div class="timeline-text">
                                    <h3>Step 1</h3>
                                    <p>Participants are Interviewed</p>
                                </div>
                                </div>
                            </div>
                            </div>

                            <div class="row timeline-left">
                            <div class="col-md-6 d-md-none d-block">
                                <p class="timeline-date"></p>
                            </div>
                            <div class="col-md-6">
                                <div class="timeline-box">
                                <div class="timeline-icon d-md-none d-block">
                                    <i class="fa fa-business-time"></i>
                                </div>
                                <div class="timeline-text">
                                    <h3>Step 2</h3>
                                    <p>Interviewees Submit Participation Application</p>
                                </div>
                                <div class="timeline-icon d-md-block d-none">
                                    <i class="fa fa-business-time"></i>
                                </div>
                                </div>
                            </div>
                            <div class="col-md-6 d-md-block d-none">
                                <p class="timeline-date"></p>
                            </div>
                            </div>

                            <div class="row">
                            <div class="col-12">
                                <div class="timeline-year"></div>
                            </div>
                            </div>

                            <div class="row timeline-right">
                            <div class="col-md-6">
                                <p class="timeline-date"></p>
                            </div>
                            <div class="col-md-6">
                                <div class="timeline-box">
                                <div class="timeline-icon">
                                    <i class="fa fa-briefcase"></i>
                                </div>
                                <div class="timeline-text">
                                    <h3>Step 3</h3>
                                    <p>Participants Submit Questionnaire</p>
                                </div>
                                </div>
                            </div>
                            </div>

                            <div class="row timeline-left">
                            <div class="col-md-6 d-md-none d-block">
                                <p class="timeline-date"></p>
                            </div>
                            <div class="col-md-6">
                                <div class="timeline-box">
                                <div class="timeline-icon d-md-none d-block">
                                    <i class="fa fa-cogs"></i>
                                </div>
                                <div class="timeline-text">
                                    <h3>Step 4</h3>
                                    <p>Jury Reviews Questionnaire & Deliberates</p>
                                </div>
                                <div class="timeline-icon d-md-block d-none">
                                    <i class="fa fa-cogs"></i>
                                </div>
                                </div>
                            </div>
                            <div class="col-md-6 d-md-block d-none">
                                <p class="timeline-date"></p>
                            </div>
                            </div>

                            <div class="row timeline-right">
                            <div class="col-md-6">
                                <p class="timeline-date"></p>
                            </div>
                            <div class="col-md-6">
                                <div class="timeline-box">
                                <div class="timeline-icon">
                                    <i class="fa fa-running"></i>
                                </div>
                                <div class="timeline-text">
                                    <h3>Step 5</h3>
                                    <p>Winners are Announced</p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        {/* <div class="timeline-start">
                            <p>Launch</p>
                        </div>
                        <div class="timeline-launch">
                            <div class="timeline-box">
                            <div class="timeline-text">
                                <h3>Launched our company on 01 Jan 2019</h3>
                                <p>Lorem ipsum dolor sit amet</p>
                            </div>
                            </div>
                        </div> */}
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className="auto-container" style={{marginTop: '30px'}}>
                <div className="sec-title title-anim centered">
                    {/* <div className="sec-title_title">Our Speakers</div> */}
                    <h2 className="sec-title_heading">Things To Remember</h2>
                    <div className="col-lg-12 col-md-12 col-sm-12" style={{marginTop: '40px'}}>
                        <ul className="accordion-box style-two">
                        <li className="accordion block active-block">
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>Does participating in the Forttuna Global Excellence Awards guarantee that I will win? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        Participation in the Fortuna Global Excellence Awards does not guarantee a win. Our program is jury-led and merit-based. Our winners are decided by the Jury.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>Is this a paid award? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        No, the Forttuna Global Excellence Awards is not a paid award. We celebrate and honor outstanding individuals and organizations in their respective industry based on their merits, contributions, and achievements rather than financial considerations. It is our commitment to recognizing excellence.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>How did I get invited for an interview? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        The shortlisting process is a result of meticulous research conducted by our dedicated team in collaboration with a research unit. We employ comprehensive criteria, including recommendations, educational qualifications, professional experience, outstanding accomplishments, and recognition in your respective sector. This thorough evaluation identifies individuals who stand out within the industry.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>How does the Jury evaluate applications? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        <p>The Jury evaluates applications through a comprehensive three-level internal screening process. This method ensures a thorough and unbiased assessment of each candidate&rsquo;s qualifications. Here&rsquo;s how the evaluation is structured:</p>
                                        <p><strong>Initial Review by the Preliminary Jury:</strong> This first level involves a preliminary assessment of applications. The jury conducts an initial review to determine adherence to the application guidelines.</p>
                                        <p><strong>Detailed Review by a Jury Lead:</strong> Applications that pass the initial review proceed to this stage, where a Jury Lead conducts an in-depth evaluation of the candidate&rsquo;s application, submission, and supporting documents along with the candidate&rsquo;s credentials, achievements, and overall suitability.</p>
                                        <p><strong>Final Review by the Grand Jury:</strong> The final level of scrutiny is conducted by our Grand Jury. Only the applications that excel through the previous stages make it to this round, ensuring that only the most outstanding candidates are considered.</p>
                                        <p><strong>PLEASE NOTE:</strong><strong> Scores are assigned objectively based on the evidence and information provided by each candidate in their questionnaire responses. APPLICATIONS THAT DO NOT MEET THE REQUIRED STANDARDS AT ANY LEVEL ARE ELIMINATED FROM THE PROCESS.</strong></p>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>Will I receive feedback from the jury on my application? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        Detailed feedback from the jury is not provided; however, you will be notified of the outcome once the deliberation is complete.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>Can Indians residing outside of India apply? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        Yes, Indians living outside of India are eligible to apply. They can submit applications for both the Global Edition and the India Edition of the award.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* <PastWinner/> */}
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
    )
};
export default SpeakerPage;
