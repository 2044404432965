import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Banking-Tech-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)

    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards: Banking Tech Awards';
    }, []);
    return (
        <Fragment>
             <Helmet>
                <meta name="description" content="Position your bank as a global leader in technology with The Forttuna Global Excellence Banking Tech Awards." />
                <meta property="og:title" content="Forttuna Global Excellence Awards: Banking Tech Awards" />
                <meta property="og:description" content="Position your bank as a global leader in technology with The Forttuna Global Excellence Banking Tech Awards." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="keywords" content="Banking Tech Awards" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='Forttuna Global Excellence Awards: Banking Tech Awards' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="Forttuna Banking Tech Awards" />
                        </div>
                        <h4 style={{color: '#fff'}}><strong>The Forttuna Global Excellence Awards: Banking Tech Awards</strong></h4>
                        <h1 style={{fontSize :'40px' , lineHeight: '43px', color: '#fff'}}><strong>First-ever Jury Led</strong> Banking Tech <strong>Awards: Be Recognized on the World Stage</strong></h1>
                        <p>The Forttuna Global Excellence Banking Tech Awards recognize and honor the groundbreaking innovations transforming the banking industry. By celebrating the fintech pioneers driving digital transformation, efficiency, and customer experience, these awards highlight the technology-driven solutions shaping the future of finance.</p>
                        <h3 style={{color: '#fff'}}><strong>What are the Forttuna Global Excellence Awards?</strong></h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Recognize Banking Innovation:</strong> Celebrate groundbreaking achievements in banking technology.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Expert Validation:</strong> Benefit from a rigorous evaluation by industry experts.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Drive Industry Advancement:</strong> Showcase your solution's impact on the banking sector.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Connect with Leaders:</strong> Network with fellow banking tech innovators and collaborate.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Gain Global Recognition:</strong> Position your bank as a global leader in technology.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}><strong>Why Participate? (Insert pictures of past awardees on the side)</strong></h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Showcase Innovation:</strong> Be recognized as a leader in banking technology innovation.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Inspire and Connect:</strong> Network with industry peers and foster collaboration.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Elevate Your Brand:</strong> Increase brand visibility and attract new business opportunities.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Gain Media Attention:</strong> Secure significant media coverage for your achievements.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Expert Endorsement:</strong> Receive validation from a panel of industry experts.</li>
                        </ul>
                        <h4 style={{color: '#fff'}}><strong>Banking Tech Awards <a href="https://forttuna.co/award-categories/">Categories</a>:</strong></h4>
                        <p><strong>Finance and Insurance-</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Angel Investor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>Technology-</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Angel Investor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <h5 style={{color: '#fff'}}><strong>Ready to shine a light? Nominate yourself or someone you know for recognition.</strong></h5>
                        <p>Shape the future of banking technology. Be recognized as a global industry leader.</p>
                        <p>Let's celebrate your achievements together at this Banking Tech Award!</p>
                        <p><a href="https://participate.forttuna.co/">Participate Now or Start Your Participate</a></p>
                        <p><a href="https://india.forttuna.co/">Nominate Now</a> and be a part of Forttuna India.</p>
                        <p><a href="https://forttuna.co/awards/health-professionals-award/">Learn More</a> About the Excellence Award.</p>
                        <p>Know About <a href="https://forttuna.co/awards/fintech-breakthrough-awards/">Fintech Breakthrough Awards.</a></p>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
    )
};
export default EventSinglePage;
