import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Health-Innovation-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards : Health Professionals';
    }, []);
    return (
        <Fragment>
            <Helmet>
                <meta name="description" content="Award of Excellence to honor individuals and companies through health professionals award making an impact on the global landscape." />
                <meta property="og:title" content="Forttuna Global Excellence Awards : Health Professionals" />
                <meta property="og:description" content="Award of Excellence to honor individuals and companies through health professionals award making an impact on the global landscape." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='Forttuna Global Excellence Awards : Health Professionals' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="" />
                        </div>
                        <div className="upper-box d-flex justify-content-between flex-wrap">
                            <h2 style={{color: '#fff'}}>The Forttuna Global Excellence Awards: Health Professionals Awards</h2><br/>
                            {/* <div className="event-detail_author">
                                <div className="event-detail_author-image">
                                    <img src={eimg1} alt="" />
                                </div>
                                <div className="event-detail_author-name">Robert Gates, <span>Art Critic</span></div>
                                <div className="event-detail_author-location"><span>Location:</span> Hall 1, Building, <br /> Golden Street , Southafrica</div>
                                <div className="event-detail_author-timing">08.00 am  09.00 am</div>
                                <div className="event-detail_author-icon flaticon-voice"></div>
                            </div> */}
                        </div>
                        <div>
                            <p><strong>First-ever Jury Led Awards: Be Recognized on the World Stage</strong></p>
                            <p>
                                <span>
                                    The healthcare industry is constantly evolving, pushed forward by the tireless efforts of health innovation professionals. These forward-thinking individuals dedicate themselves to developing groundbreaking solutions that improve
                                    patient care, redefine treatment options, and ultimately, save lives.
                                </span>
                            </p>
                            <p><span>The Forttuna Global Excellence Awards - Health Innovation Awards aim to celebrate these exceptional minds on a global platform.</span></p>
                            <p><strong>What are the Forttuna Global Excellence Awards?</strong></p>
                            <ul>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>A prestigious jury-led and merit-based health innovation awards program recognizing exceptional achievements across various industries.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Award of Excellence to honor individuals and companies through health innovation awards making a significant impact on the global landscape.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>More than just global awards - a chance to connect and collaborate with like-minded individuals across diverse fields.</span></li>
                            </ul>
                            <p><strong>Why Participate</strong></p>
                            <ul>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Gain global recognition for your company's innovation and leadership.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Showcase individual talent and inspire others through achievement.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Network with industry leaders at our prestigious health innovation awards ceremony.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Elevate your brand profile and attract new opportunities.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Gain significant media exposure, propelling your achievements into the spotlight.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Fair evaluation by a distinguished panel of international jury experts.</span></li>
                            </ul>
                            <p>
                                <strong>Health Innovation Awards Categories :</strong><span> (All Categories for this award will contain subcategories too- like CMO of the year, CTO of the year as mentioned on the website. As shown in the first two categories )</span>
                            </p>
                            <ol>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Ayush, Spa, Wellness and Yoga Category</strong></li>
                            </ol>
                            <ol>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Ayurveda Centre Of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Medical Spa/Medical Resort Of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Yoga Centre Of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Wellness Centre/ Retreat Of the Year</span></li>
                            </ol>
                            <ol>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Company and Brands</strong></li>
                            </ol>
                            <ol>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Health Insurance Company Of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Long-Tem Care Provider Of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Pharma Company Of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Pharmacy Retail Chain Of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Emerging Healthcare Company Of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Medical Equipment Company Of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Healthcare Start-up of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Public Healthcare Initiative of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Innovative Medical Product of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Marketing Company Of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Insurance and Finance Company Of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Technology Company Of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Education Company Of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Veterinary Company Of the Year</span></li>
                            </ol>
                            <ol>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong> CXO&rsquo;s Health &amp; Wellness</strong></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Hospital &amp; Clinic Category</strong></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Individual Category</strong></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Medical College/Institute Category</strong></li>
                            </ol>
                            <p><strong>Ready to shine a light? Participate yourself or someone you know for recognition.</strong></p>
                            <p><span>Let's celebrate your achievements together at this Health Innovation Awards!</span></p>
                            <p><a href="https://calendly.com/d/ck9b-2wq-pjs/participation-interview-fga?month=2024-07"><span>Book An Appointment Now!</span></a></p>
                        </div>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
    )
};
export default EventSinglePage;
