import timg1 from '../images/resource/jimmy-mistry.webp'
import timg2 from '../images/resource/manjulangani.webp'
import timg3 from '../images/resource/bimal-chajjar.webp'
import timg4 from '../images/resource/Amarjit-Singh.jpg'
import timg5 from '../images/resource/Arun-Kumar-Singh.jpg'
import timg6 from '../images/resource/amit-sheth.webp'
import timg7 from '../images/resource/Subramanyam-Yadavalli2.webp'
import timg8 from '../images/resource/speaker-1.jpg'
import timg9 from '../images/resource/pratit-samdani.webp'
import timg10 from '../images/resource/riitesh-mehtaa.webp'
import timg11 from '../images/resource/Anjlee.jpg'
import timg12 from '../images/resource/shailza-taneja.jpg'

import tsimg1 from '../images/resource/jimmy-mistry.webp'
import tsimg2 from '../images/resource/manjulangani.webp'
import tsimg3 from '../images/resource/bimal-chajjar.webp'
import tsimg4 from '../images/resource/Amarjit-Singh.jpg'
import tsimg5 from '../images/resource/Arun-Kumar-Singh.jpg'
import tsimg6 from '../images/resource/amit-sheth.webp'
import tsimg7 from '../images/resource/Subramanyam-Yadavalli2.webp'
import tsimg8 from '../images/resource/speaker-1.jpg'
import tsimg9 from '../images/resource/pratit-samdani.webp'
import tsimg10 from '../images/resource/riitesh-mehtaa.webp'
import tsimg11 from '../images/resource/Anjlee.jpg'
import tsimg12 from '../images/resource/shailza-taneja.jpg'
// import tsimg8 from '../images/resource/team-16.jpg'


const Teams = [
   {
      Id: '1',
      tImg: timg3,
      tsImg: tsimg3,
      name: 'Dr. Bimal Chhajer',
      slug: 'Dr. Bimal Chhajer',
      title: "Cardiologist, Founder, Saaol Heart Center, India",
   },
   {
      Id: '2',
      tImg: timg2,
      tsImg: tsimg2,
      name: 'Padmashri Dr. Manjula Anagani',
      slug: 'Padmashri Dr. Manjula Anagani',
      title: 'Clinical Director, Women and Child Institute, Care Hospital, India',
   },
   {
      Id: '3',
      tImg: timg1,
      tsImg: tsimg1,
      name: 'Jimmy Mistry',
      slug: 'Jimmy Mistry',
      title: 'CMD, Della Group, Singapore',
   },
   {
      Id: '4',
      tImg: timg6,
      tsImg: tsimg6,
      name: 'Amit Sheth',
      slug: 'Amit Sheth',
      title: 'Award-winning Serial Entrepreneur & Philanthropist, India',
   },
   {
      Id: '5',
      tImg: timg9,
      tsImg: tsimg9,
      name: 'Dr. Pratit Samdani',
      slug: 'Dr. Pratit Samdani',
      title: 'Oncologist, Physician & Intensivist, India',
   },
   {
      Id: '6',
      tImg: timg10,
      tsImg: tsimg10,
      name: 'Riitesh Mehtaa',
      slug: 'Riitesh Mehtaa',
      title: 'Serial Entrepreneur & Business Advisor',
   },
   {
      Id: '7',
      tImg: timg7,
      tsImg: tsimg7,
      name: 'Subramanyam Yadavalli',
      slug: 'Subramanyam Yadavalli',
      title: 'CEO, Apollo Hospitals',
   },
   {
      Id: '8',
      tImg: timg4,
      tsImg: tsimg4,
      name: 'Dr.(Brig) Amarjit Singh',
      slug: 'Dr.(Brig) Amarjit Singh',
      title: 'CEO & Principal Director, Dr. D. Y. Patil Medical College Hospital & Research Centre',
   },
   {
      Id: '9',
      tImg: timg5,
      tsImg: tsimg5,
      name: 'Dr. Arun Kumar Singh',
      slug: 'Dr. Arun Kumar Singh',
      title: 'Development Commissioner & Additional Chief Secretary of Health, Government of Jharkhand',
   },
   {
      Id: '10',
      tImg: timg11,
      tsImg: tsimg11,
      name: 'Dr. Anjlee Prakash',
      slug: 'Dr. Anjlee Prakash',
      title: 'Founder & Chairperson, Learning Links Foundation, India',
   },
   {
      Id: '11',
      tImg: timg12,
      tsImg: tsimg12,
      name: 'Shailza Taneja',
      slug: 'Shailza Taneja',
      title: 'Strategic Advisor, Deloitte',
   },
]

export default Teams;