import React, { Fragment, useEffect, useState } from 'react';
// import React from 'react';
import InstagramFeed from './InstagramFeed';
import CookieConsent from "react-cookie-consent";
// import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom'
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import Logo from '../../images/logo.png'
import shape1 from '../../images/icons/icon-5.png'
import shape2 from '../../images/icons/icon-6.png'
import shape3 from '../../images/icons/icon-7.png'
import shape4 from '../../images/icons/icon-8.png'
import shape5 from '../../images/icons/icon-9.png'
import shape6 from '../../images/icons/icon-10.png'


import pImg1 from '../../images/gallery/1.webp'
import pImg2 from '../../images/gallery/2.webp'
import pImg3 from '../../images/gallery/3.webp'
import pImg4 from '../../images/gallery/4.webp'
import pImg5 from '../../images/gallery/5.webp'
import pImg6 from '../../images/gallery/6.webp'
import pImg7 from '../../images/gallery/7.webp'
import pImg8 from '../../images/gallery/8.webp'

const images = [
    pImg1,
    pImg2,
    pImg3,
    pImg4,
    pImg5,
    pImg6,
    pImg7,
    pImg8,
]

const RECAPTCHA_SITE_KEY = "6LcfDx0qAAAAADmBLyawaaeiP9Kxx7N52xxKnFjC";
const Footer2 = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    const handleAcceptCookies = () => {
        // Logic to enable tracking scripts, analytics, etc.
        console.log("Cookies accepted!");
      };
      const [recaptchaValue, setRecaptchaValue] = useState(null);
    
      const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
      };
    const [open, setOpen] = React.useState(false);

    return (
        <footer className="footer-two">
            {/* <div className="footer-two_icon-one" style={{ backgroundImage: `url(${shape1})` }}></div>
            <div className="footer-two_icon-two" style={{ backgroundImage: `url(${shape1})` }}></div>
            <div className="footer-two_icon-three" style={{ backgroundImage: `url(${shape2})` }}></div>
            <div className="footer-two_icon-four" style={{ backgroundImage: `url(${shape2})` }}></div>
            <div className="footer-two_icon-five" style={{ backgroundImage: `url(${shape3})` }}></div>
            <div className="footer-two_icon-six" style={{ backgroundImage: `url(${shape4})` }}></div>
            <div className="footer-two_icon-seven" style={{ backgroundImage: `url(${shape5})` }}></div> */}
            <div className="footer-two_icon-eight" style={{ backgroundImage: `url(${shape6})` }}></div>
            <div className="auto-container">
                <div className="footer-two-widgets">
                    <div className="row clearfix">
                        <div className="big-column col-lg-6 col-md-12 col-sm-12">
                            <div className="row clearfix">
                                <div className="footer-two_column col-lg-7 col-md-6 col-sm-12">
                                    <div className="footer-two-widget logo-widget">
                                        <div className="footer-two_logo">
                                            <Link onClick={ClickHandler} to="/" ><img src={Logo} alt="" /></Link>
                                        </div>
                                        <div className="footer-two_text">The Forttuna Global Excellence Awards is dedicated to recognizing and honoring outstanding achievements across various industries.</div>
                                    </div>
                                </div>
                                <div className="footer-two_column col-lg-5 col-md-6 col-sm-12">
                                    <div className="footer-two-widget links-widget">
                                        <h4 className="footer-two_title">Links</h4>
                                        <ul className="footer-two_list">
                                            <li><Link onClick={ClickHandler} to="/who-we-are/">Who We Are</Link></li>
                                            <li><Link onClick={ClickHandler} to="/team/">The Team</Link></li>
                                            <li><Link onClick={ClickHandler} to="/our-aim/">Our Aim</Link></li>
                                            <li><Link onClick={ClickHandler} to="/contacts/">Contact</Link></li>
                                            <li><Link onClick={ClickHandler} to="/faq/">FAQ'S</Link></li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="big-column col-lg-6 col-md-12 col-sm-12">
                            <div className="row clearfix">
                                <div className="main-footer_column col-lg-6 col-md-6 col-sm-12">
                                    <div className="footer-widget news-widget">
                                        <h4 className="footer-two_title">Information</h4>
                                        <ul className="footer-two_contact-list">
                                            <li><i className="icon fa fa-phone"></i>+971 55 595 7093</li>
                                            <li><i className="icon fa fa-envelope"></i> <a href="mailto:contact@forttuna.co">contact@forttuna.co</a></li>
                                            <li><i className="icon fa fa-map-marker"></i>HQ: Burj Al Salam, Trade Center 1, Dubai, UAE.</li>
                                        </ul>
                                        <ul className="footer-two_socials">
                                            <li><a target='_blank' href="https://www.linkedin.com/company/forttunaawards" className="fa fa-linkedin"></a></li>
                                            <li><a target='_blank' href="https://www.instagram.com/forttunaawards/" className="fa fa-instagram"></a></li>
                                            <li><a target='_blank' href="https://www.facebook.com/forttunaaward" className="fa fa-facebook"></a></li>
                                            <li><a target='_blank' href="https://www.youtube.com/@ForttunaAwards" className="fa fa-youtube"></a></li>
                                            <li><a target='_blank' href="https://x.com/ForttunaAwards" className="fa fa-twitter"></a></li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="main-footer_column col-lg-6 col-md-6 col-sm-12">
                                    <div className="footer-widget instagram-widget">
                                        <h4 className="footer-two_title">instagram</h4>
                                        <div className="images-outer clearfix">
                                            {/* {images.slice(0, 6).map((item, pitem) => (
                                                <figure className="image-box" key={pitem} onClick={() => setOpen(true)}><img src={item} alt="" /></figure>
                                            ))} */}
                                            {/* <figure className="image-box"  alt=""><InstagramFeed /></figure> */}
                                            {/* <div className="App">    */}
                                            <InstagramFeed />
                                            {/* </div> */}
                                            {/* <InstaFeeds token={process.env.IGQWRPT0pSb2RHc2NGQjU4M3I3dlRTRndGZAUI2VEQyc1dramFzMUNVYlJpNXBJTkF6MkFfMnRYa25qS19QdTBJdUd6VWU2UjV3X1YyRmJEbG5NcVF6LS1TVXJQQUlMelRVUWw5QUllSjdFazI1czYxc0huOTlZAMFkZD} limit={6}/> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div className="footer-bottom">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="column col-lg-6 col-md-12 col-sm-12">
                            <div className="copyright"> <span>Copyright 2024 &copy; All Rights Reserved by </span><Link onClick={ClickHandler} to="https://theforttunagroup.com/" target="_blank" rel="noopener">The Forttuna Group</Link></div>
                        </div>
                        <div className="column col-lg-6 col-md-12 col-sm-12">
                            <ul className="footer-bottom-nav">
                                <li><Link onClick={ClickHandler} to="/terms-of-use/">Terms and Conditions</Link></li>
                                <li><Link onClick={ClickHandler} to="/privacy-policy/">Privacy Policy</Link></li>
                                <li><Link onClick={ClickHandler} to="/cookie-policy/">Cookie Policy</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={[
                    { src: pImg1 },
                    { src: pImg2 },
                    { src: pImg3 },
                    { src: pImg4 },
                    { src: pImg5 },
                    { src: pImg6 },
                ]}
                plugins={[Zoom]}
            />
            <CookieConsent
                location="bottom"
                buttonText="Accept"
                cookieName="myAwesomeCookieName"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
                onAccept={handleAcceptCookies}
                >
                This website uses cookies to enhance the user experience.{" "}
                <a href="/privacy-policy" style={{ color: "#ffffff" }}>
                    Learn more
                </a>
            </CookieConsent>
            <a href="https://wa.me/971521965093?text=Hello" class="float" target="_blank">
                <i class="fa fa-whatsapp my-float"></i>
            </a>
        </footer>
        
    )
}

export default Footer2;