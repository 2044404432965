import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Nurse-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards: Nurse Awards';
    }, []);
    return (
        <Fragment>
             <Helmet>
                <meta name="description" content="Forttuna Global Excellence Nurse Awards aims to honor exceptional nurses who exemplify compassion and expertise." />
                <meta property="og:title" content="Forttuna Global Excellence Awards: Nurse Awards" />
                <meta property="og:description" content="Forttuna Global Excellence Nurse Awards aims to honor exceptional nurses who exemplify compassion and expertise." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="keywords" content="Nurse Awards" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='Forttuna Global Excellence Awards: Nurse Awards' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="Forttuna Nurse Awards" />
                        </div>
                        <h4 style={{color: '#fff'}}><strong>The Forttuna Global Excellence Awards: Nurse Awards</strong></h4>
                        <h1 style={{fontSize :'40px' , lineHeight: '43px', color: '#fff'}}><strong>First-ever Jury Led</strong> Nurse Awards: Be Recognized on the World Stage</h1>
                        <p>The Forttuna Global Excellence Nurse Awards honor the unsung heroes who dedicate their lives to caring for others. By recognizing exceptional nurses who demonstrate compassion, expertise, and innovation, these awards celebrate the invaluable contributions of the nursing profession to global healthcare.</p>
                        <h3 style={{color: '#fff'}}><strong>What are the Forttuna Global Excellence Awards?</strong></h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Celebrate Nursing Excellence:</strong> Honor exceptional nurses who exemplify compassion and expertise.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Expert Recognition:</strong> Receive prestigious recognition from leading healthcare professionals.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Impactful Care:</strong> Acknowledge nurses making a significant difference in patient lives.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Inspire and Connect:</strong> Network with fellow nursing professionals and share best practices.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Global Leadership:</strong> Showcase your contributions to the global nursing community.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}><strong>Why Participate? Insert pictures of past awardees on the side.</strong></h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Celebrate Nursing Excellence:</strong> Honor the dedication and compassion of exceptional nurses.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Expert Recognition:</strong> Receive prestigious recognition from healthcare leaders.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Inspire Future Nurses:</strong> Be a role model for the next generation of healthcare professionals.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Connect with Peers:</strong> Network with fellow nurses and build lasting relationships.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Gain Global Acclaim:</strong> Showcase your nursing expertise on a global stage.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Expert Validation:</strong> Receive expert recognition for your outstanding contributions.</li>
                        </ul>
                        <h4 style={{color: '#fff'}}><strong>Nurse Award <a href="https://forttuna.co/award-categories/">Categories</a> :</strong></h4>
                        <p><strong>A. Ayush, Spa, Wellness and Yoga Category</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Ayurveda Centre Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Medical Spa/Medical Resort Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Yoga Centre Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Wellness Centre/ Retreat Of the Year</li>
                        </ol>
                        <p><strong>B. Company and Brands</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Health Insurance Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Long-Tem Care Provider Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pharma Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pharmacy Retail Chain Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Healthcare Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Medical Equipment Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Start-up Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Public Healthcare Initiative Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Medical Product Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Marketing Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Insurance and Finance Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Technology Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Education Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Veterinary Company Of the Year</li>
                        </ol>
                        <p><strong>C. CXO&rsquo;s Health &amp; Wellness</strong></p>
                        <p><strong>D. Hospital &amp; Clinic Category</strong></p>
                        <p><strong>E. Individual Category</strong></p>
                        <p><strong>F. Medical College/Institute Category</strong></p>
                        <h5 style={{color: '#fff'}}><strong>Ready to shine a light?</strong></h5>
                        <h6 style={{color: '#fff'}}><strong>Nominate yourself or someone you know for recognition.</strong></h6>
                        <p>Let's celebrate the extraordinary contributions of nurses worldwide at the Forttuna Global Excellence Nurse Awards. Join us in honoring the compassionate, skilled, and innovative professionals who dedicate their lives to patient care.</p>
                        <p><a href="https://participate.forttuna.co/">Participate Now or Start Your Participation</a></p>
                        <p><a href="https://india.forttuna.co/">Participate</a> in Forttuna India Awards.</p>
                        <p><a href="https://forttuna.co/awards/manufacturing-awards/">Learn More</a> About the Excellence Award.</p>
                        <p>Know About <a href="https://forttuna.co/awards/health-awards/">Forttuna Health Awards</a></p>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
    )
};
export default EventSinglePage;
